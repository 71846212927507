body { background-color: $COL2; }
body { color: $COL3; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Nanum Gothic';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 30px;

}
}
h2 {
font-family: 'Nanum Gothic';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
}
h3 {
font-family: 'Nanum Gothic';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 28px;

}
}
h4 {
font-family: 'Nanum Gothic';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
font-family: 'Nanum Gothic';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

@media #{$large-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Nanum Gothic';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 26px;

@media #{$medium-up} {
font-size: 30px;

}
}
.button {
font-family: 'Nanum Gothic';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Nanum Gothic';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Nanum Gothic';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Nanum Gothic';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 30px;

}
}
summary {
font-family: 'Nanum Gothic';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Nanum Gothic';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 18px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Nanum Gothic';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 18px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Nanum Gothic';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Nanum Gothic';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Nanum Gothic';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 14px;

}
@media #{$large-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: $COL14}
a:hover {color: $COL15}
/* Primary:2 */
.MES2 {
background-color: $COL4;
color: $COL5;
 }
/* Primary:3 */
.MES3 {
background-color: $COL4;
color: $COL5;
 }
.MES3 {
background-color: $COL4;
color: $COL5;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL5;
 }
 }
cite.MEC3{
color: $COL5;
}
/* Secondary:4 */
.MES4 {
background-color: $COL6;
color: $COL8;
&:hover { color: $COL2;}
 }
/* Secondary:5 */
.MES5 {
background-color: $COL6;
color: $COL8;
 }
.MES5 {
background-color: $COL6;
color: $COL8;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL8;
 }
 }
cite.MEC5{
color: $COL8;
}
/* Alternate:6 */
.MES6 {
background-color: $COL9;
 }
/* Alternate:7 */
.MES7 {
background-color: $COL9;
 }
/* Alternate:8 */
.MES8 {
background-color: $COL9;
 }
.MES8 {
background-color: $COL9;
 }
/* Alternate:9 */
.MES9 {
background-color: $COL9;
 }
.MES9 {
background-color: $COL9;
 }
/* Light:10 */
.MES10 {
background-color: $COL11;
 }
/* Light:11 */
.MES11 {
background-color: $COL11;
 }
.MES11 {
background-color: $COL11;
 }
/* Shade 1:12 */
.MES12 {
background-color: $COL12;
color: $COL13;
 }
/* Shade 1:13 */
.MES13 {
background-color: $COL12;
color: $COL13;
 }
.MES13 {
background-color: $COL12;
color: $COL13;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: $COL13;
 }
 }
cite.MEC13{
color: $COL13;
}
/* Copyright panel:14 */
.MES14 {
font-size: 11.2px;
@media #{$medium-up} {
font-size: 9.8px;
};
@media #{$large-up} {
font-size: 11.2px;
};
 }
.MES14 {
font-size: 11.2px;
@media #{$medium-up} {
font-size: 9.8px;
};
@media #{$large-up} {
font-size: 11.2px;
};
 }
cite.MEC14{
font-size: 11.2px;
@media #{$medium-up} {
font-size: 9.8px;
};
@media #{$large-up} {
font-size: 11.2px;
};
}
/* News Grid:16 */
.MES16 {
background-color: $COL1;
&:hover, &.hover { background-color: $COL9;}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: $COL11;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES16 {
background-color: $COL1;
&:hover, &.hover { background-color: $COL9;}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: $COL11;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Main Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: $COL4;
text-transform: uppercase;
}
 &:hover > a.MEC17{color: $COL16;
}
 }
&.horizontal > .menu-item.MEC17 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 15px 20px;}




.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Paypal button:18 */
.MES18 {
background-color: $COL17;
color: $COL2;
 }
/* Home slider:19 */
.MES19 {
& .slider-arrow {color: $COL18;
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: $COL16;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: $COL11;
;}
background-color: $COL2;
background-clip: padding-box;
&:hover {background-color: $COL11;}
width:3px;
height:3px;
@media #{$medium-up} {
width:3px;
height:3px;
};
@media #{$large-up} {
width:3px;
height:3px;
};
&:hover{background-color: $COL11;
;}}}
& .slick-dots .slick-active {
  button{
border-color: $COL11;
background-color: $COL11;
  }
}
 }
/* Responsive Menu:20 */
nav.responsive-menu {
.menu-item.MEC20{background-color: $COL11;
}
& .menu-item.MEC20, & .menu-item.MEC20 > div.menu-item-wrap{ & > a.MEC20, & > i{color: $COL4;
text-transform: none;
text-align: center;
}
  }
& .menu-item.MEC20 { border:0;

 border-bottom-width: 1px; }
& .sub-menu{ .sub-menu {}}

 }
/* login buttons:21 */
.MES21 {
background-color: $COL11;
color: $COL4;
border-radius: 50px;
padding: 5px 15px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Copyright panel grey:22 */
.MES22 {
background-color: $COL11;
font-size: 12.8px;
@media #{$medium-up} {
font-size: 11.2px;
};
@media #{$large-up} {
font-size: 12.8px;
};
 }
.MES22 {
background-color: $COL11;
font-size: 12.8px;
@media #{$medium-up} {
font-size: 11.2px;
};
@media #{$large-up} {
font-size: 12.8px;
};
 }
cite.MEC22{
font-size: 12.8px;
@media #{$medium-up} {
font-size: 11.2px;
};
@media #{$large-up} {
font-size: 12.8px;
};
}
/* carousel padding:23 */
.MES23 {
padding: 10px;

 }
.MES23 {
padding: 10px;

 }
/* Carousel grey pad:24 */
.MES24 {
background-color: $COL9;
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES24 {
background-color: $COL9;
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
/* border line:25 */
.MES25 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL10 $COL1 $COL1 $COL1;}
& > hr {border-top-style: solid;}
 }
/* Top Strip Panel:26 */
.MES26 {
background-color: $COL2;
color: $COL4;
font-size: 16px;
@media #{$medium-up} {
font-size: 14px;
};
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL11 transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES26 {
background-color: $COL2;
color: $COL4;
font-size: 16px;
@media #{$medium-up} {
font-size: 14px;
};
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL11 transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: $COL6;
 }
h1.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h1.MEC26 { @media #{$large-up} { font-size: 30px; }; }
h2.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h2.MEC26 { @media #{$large-up} { font-size: 30px; }; }
h3.MEC26 { @media #{$medium-up} { font-size: 22.4px; }; }
h3.MEC26 { @media #{$large-up} { font-size: 28px; }; }
h4.MEC26 { @media #{$medium-up} { font-size: 20px; }; }
h4.MEC26 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC26 { @media #{$medium-up} { font-size: 12.8px; }; }
h5.MEC26 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h6.MEC26 { @media #{$large-up} { font-size: 30px; }; }
 }
a.MEC26 { color: $COL4;
&:hover { color: $COL6; }
 }
cite.MEC26{
color: $COL4;
font-size: 16px;
@media #{$medium-up} {
font-size: 14px;
};
@media #{$large-up} {
font-size: 12.8px;
};
}
/* Banner Panel:27 */
.MES27 {
background-color: $COL1;
 }
.MES27 {
background-color: $COL1;
 }
